import React, { ReactNode, useContext } from 'react';
import clsx from 'clsx';
import Container from 'react-bootstrap/Container';
import Accordion from 'react-bootstrap/Accordion';
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';
import AccordionContext from 'react-bootstrap/AccordionContext';

import { faqs } from '@/constants/faqs';

import chevronDown from '@/assets/icons/down.svg';
import chevronUp from '@/assets/icons/up.svg';

import * as styles from './faq.module.scss';

interface AccordionToggleProps {
  text: string;
  children: ReactNode;
  eventKey: string;
  callback?: (eventKey: string) => any;
}

const AccordionToogle = ({ text, children, eventKey, callback }: AccordionToggleProps) => {
  const currentEventKey = useContext(AccordionContext);
  const decoratedOnClick = useAccordionToggle(eventKey, () => callback && callback(eventKey));

  const isShowing = currentEventKey === eventKey;

  return (
    <button
      onClick={decoratedOnClick}
      className={clsx(styles.accordionButton, {
        [styles.accordionShowing]: isShowing,
      })}
    >
      <div className={styles.accordionHeader}>
        <span>{text}</span>
        <img src={isShowing ? chevronUp : chevronDown} alt="mostrar botão" loading="lazy" />
      </div>
      <>{children}</>
    </button>
  );
};

const FAQ = () => (
  <div className={styles.view}>
    <Container className={styles.container}>
      <p>FAQ</p>
      <h2>Tire suas dúvidas e faça parte da comunidade Simples Dental!</h2>

      <Accordion className={styles.accordion}>
        {faqs.map(({ key, question, answer }) => (
          <AccordionToogle eventKey={key} text={question} key={key}>
            <Accordion.Collapse eventKey={key}>
              <div className={styles.accordionBody}>{answer}</div>
            </Accordion.Collapse>
          </AccordionToogle>
        ))}
      </Accordion>
    </Container>
  </div>
);

export default FAQ;
