import clsx from 'clsx';
import React, { ChangeEventHandler } from 'react';
import { Form } from 'react-bootstrap';
import * as styles from './switch.module.scss';

export interface SwitchProps {
  handleChange: ChangeEventHandler;
  isAnual: boolean;
}

const Switch = ({ handleChange, isAnual }: SwitchProps) => (
  <div className={styles.container}>
    <div>
      <span className={clsx(styles.text, { [styles.active]: !isAnual })}>Pagamento Mensal</span>
      <span className={clsx(styles.xsText, { [styles.active]: !isAnual })}>Mensal</span>
      <Form.Check
        type="switch"
        onChange={handleChange}
        id="custom-switch"
        className={styles.customSwitch}
        checked={isAnual}
      />
      <span className={clsx(styles.text, { [styles.active]: isAnual })}>
        Pagamento Anual <strong>(mais econômico)</strong>
      </span>
      <span className={clsx(styles.xsText, { [styles.active]: isAnual })}>Anual</span>{' '}
    </div>
  </div>
);

export default Switch;
