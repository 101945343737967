export interface PlanoDetalhes {
  descricao: string;
  agenda: boolean;
  gestao: boolean;
  vendas: boolean;
  isNew?: boolean;
  isInnovation?: boolean;
  isPreLaunch?: boolean;
  info?: string;
}

export const planosDetalhes: PlanoDetalhes[] = [
  {
    descricao: 'Agenda integrada à Alexa',
    agenda: true,
    gestao: true,
    vendas: true,
  },
  {
    descricao: 'Site para sua clínica',
    agenda: true,
    gestao: true,
    vendas: true,
  },
  {
    descricao: 'Aplicativo para clínica e para o paciente',
    agenda: true,
    gestao: true,
    vendas: true,
    isNew: false,
  },
  {
    descricao: 'Odontograma digital',
    agenda: true,
    gestao: true,
    vendas: true,
    isNew: false,
  },
  {
    descricao: 'Orçamento Parcial',
    agenda: true,
    gestao: true,
    vendas: true,
    isNew: false,
  },
  {
    descricao: 'Receituário impresso e 100% digital',
    agenda: true,
    gestao: true,
    vendas: true,
    isNew: true,
  },
  {
    descricao: 'Imagens e usuários ilimitados',
    agenda: true,
    gestao: true,
    vendas: true,
  },
  {
    descricao: 'Gestão de encaixe na agenda',
    agenda: true,
    gestao: true,
    vendas: true,
    isNew: true,
  },
  {
    descricao: 'Confirmar consultas e lembretes automáticos por WhatsApp (oficial) e SMS*',
    agenda: true,
    gestao: true,
    vendas: true,
  },
  {
    descricao: 'Mensagem automática para pós-operatório, recuperação de orçamentos e retornos*',
    agenda: true,
    gestao: true,
    vendas: true,
  },
  {
    descricao: 'Prontuário Digital - anamnese, orçamentos e documentos',
    agenda: true,
    gestao: true,
    vendas: true,
  },
  {
    descricao: 'Anamneses personalizadas, com alertas e preenchidas pelo paciente',
    agenda: true,
    gestao: true,
    vendas: true,
    isNew: true,
  },
  {
    descricao: 'Emissão de Nota Fiscal de Serviço*',
    agenda: false,
    gestao: true,
    vendas: true,
    isNew: true,
  },
  {
    descricao: 'Assinatura eletrônica (anamnese e evoluções)',
    agenda: false,
    gestao: true,
    vendas: true,
  },
  {
    descricao: 'Controle de Prótese',
    agenda: false,
    gestao: true,
    vendas: true,
  },
  {
    descricao: 'Comissão de profissionais',
    agenda: false,
    gestao: true,
    vendas: true,
  },
  {
    descricao: 'Maquininha de cartão integrada (Saúde Service)',
    agenda: false,
    gestao: true,
    vendas: true,
  },
  {
    descricao: 'Análise de Crédito',
    agenda: false,
    gestao: true,
    vendas: true,
  },
  {
    descricao: 'Indicadores e relatórios de performance ',
    agenda: false,
    gestao: true,
    vendas: true,
  },
  {
    descricao:
      'Gestão de ticket médio, lucratividade, taxa de comparecimento, aprovação de orçamentos',
    agenda: false,
    gestao: true,
    vendas: true,
  },
  {
    descricao: 'Ferramenta de Ortodontia exclusiva',
    agenda: false,
    gestao: true,
    vendas: true,
  },
  {
    descricao: 'Controle de Alinhadores',
    agenda: false,
    gestao: true,
    vendas: true,
    isNew: true,
  },
  {
    descricao: 'Inteligência Artificial: Crie suas evoluções por voz',
    agenda: false,
    gestao: false,
    vendas: true,
    isNew: true,
  },
  {
    descricao: 'Copiloto: Usar dentro do seu WhatsApp Web',
    agenda: false,
    gestao: false,
    vendas: true,
    isNew: true,
  },
  {
    descricao: 'Integração com o WhatsApp Web',
    agenda: false,
    gestao: false,
    vendas: true,
  },
  {
    descricao: 'Faceograma feminino e masculino de HOF',
    agenda: false,
    gestao: false,
    vendas: true,
  },
  {
    descricao: 'Confirmar consultas gratuitamente pelo App Meu Doutor (para pacientes)',
    agenda: false,
    gestao: false,
    vendas: true,
  },
  {
    descricao: 'Funil de Agendamento e de Vendas',
    agenda: false,
    gestao: false,
    vendas: true,
  },
  {
    descricao: 'Gerenciador de indicações para captar novos pacientes',
    agenda: false,
    gestao: false,
    vendas: true,
    isNew: true,
  },
  {
    descricao: 'Modelos de contratos',
    agenda: false,
    gestao: false,
    vendas: true,
  },
  {
    descricao: 'Assinatura eletrônica em contratos*',
    agenda: false,
    gestao: false,
    vendas: true,
  },
];
