import React from 'react';
import { Seo } from '@/components';
import LayoutPlanos from '@/layouts/Planos';

const schema = {
  '@context': 'https://schema.org/',
  '@type': 'BreadcrumbList',
  itemListElement: [
    {
      '@type': 'ListItem',
      position: 1,
      name: 'Home',
      item: 'https://www.simplesdental.com/',
    },
    {
      '@type': 'ListItem',
      position: 2,
      name: 'Planos',
    },
  ],
};

const Page = () => (
  <>
    <Seo
      title="Planos pensados para o seu consultório"
      description="Conheça os planos do melhor software para dentistas, usado por mais de 80 mil profissionais em todo o Brasil."
      schema={schema}
    />
    <LayoutPlanos />
  </>
);

export default Page;
